const pillars = {
  'Ökologie': 'ecology',
  'Ökonomie': 'economy',
  'Soziales': 'social',
  'Management': 'management',
  'Feldverwaltung': 'frs'
}

export function getPillar (route) {
  for (const name in pillars) {
    if (route.path.match(name)) {
      return pillars[name]
    }
  }
}
