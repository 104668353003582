<script>
import {get} from 'lodash'

import DlgNumericInput from './DlgNumericInput'
import DlgInputDescription from './DlgInputDescription'

import translate from 'src/js/i18n/translate'

// NOTE DLG-885 disabled for now, because it might hide incorrect/inaccurate input values. Still unsure if it is a good idea or if it should be removed completely
const showRoundedPercent = false

function calculatePercentValue (value, referenceValue, locked) {
  if (value === null || value === undefined || !referenceValue) return null

  if (!locked && showRoundedPercent) {
    // bring referenceValue to same precision as input value for questionnaire data (rounded to 2 decimal digits)
    referenceValue = parseFloat(referenceValue.toFixed(2))
  }

  return value / referenceValue
}

export default {
  functional: true,
  props: {
    data: {
      type: Object
    },
    name: String,

    unit: {
      type: String,
      default: 'ha'
    },
    integer: Boolean,
    locked: Boolean,
    rule: Function,
    percent: Boolean,

    showPercentOf: String,
    percentData: Object,

    hideDescription: Boolean,
    hasDescription: Boolean,
    title: String,
    titlePercent: String,
    placeholder: String
  },
  render (h, context) {
    const CellWrapper = content => h('div', {
      class: 'cell-wrapper'
    }, content)

    const {
      name,
      data,
      showPercentOf,
      hasDescription,
      hideDescription,
      integer,
      rule,
      percent,
      placeholder
    } = context.props

    const mainInputIsLocked = !context.listeners.input

    const value = get(data, name)
    const percentData = context.props.percentData || data.statistics

    return [
      CellWrapper([h(DlgInputDescription, {
        props: {
          name,
          hasDescription
        },
        scopedSlots: {
          description: context.scopedSlots.description
        }
      })]),
      CellWrapper([
        h(DlgNumericInput, {
          props: {
            value,
            name,
            addon: context.props.unit,
            locked: mainInputIsLocked,
            hideDescription,
            integer,
            rule,
            percent,
            placeholder,
            title: mainInputIsLocked ? translate('dlgCert.info.ask') : null
          },
          on: {
            input: value => {
              if (context.listeners.input) {
                context.listeners.input({name, value})
              }
            }
          }
        })
      ]),
      CellWrapper(!(showPercentOf && percentData) ? '' : [
        h(DlgNumericInput, {
          props: {
            value: calculatePercentValue(value, percentData[showPercentOf], mainInputIsLocked),
            addon: translate(`dlgCert.labels.percentOf_${showPercentOf}`),
            locked: true,
            percent: true,
            title: translate('dlgCert.ecology.tooltips.percent')
          }
        })
      ])
    ]
  }
}
</script>

<style lang="scss" scoped>
.cell-wrapper {
  padding: 0.125em 0.5em;
  display: flex;
  align-items: center;

  &:nth-of-type(6n + 4), &:nth-of-type(6n + 5), &:nth-of-type(6n + 6) {
    background-color: rgba(#000000, 4%);
  }
}
</style>
