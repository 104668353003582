<template lang="html">
  <div class="dlg-input-layout">
    <slot />
    <template v-if="$slots.summary">
      <slot name="summary" />
    </template>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.dlg-input-layout {
  display: grid;
  width: 70%;
  grid-template-columns: 1fr auto auto;

  &:not(:last-child) {
    margin-bottom: 2em;
  }

  &:not(:first-child) {
    margin-top: 1em;
  }
}
</style>
