<template lang="html">
  <transition mode="out-in">
    <span v-if="loading">Loading...</span>
    <div v-else class="ecology-erosion">
      <EcologyInputGrid>
        <DlgGridHeader>
          <IxRes>dlgCert.ecology.erosion.headers.structural</IxRes>
        </DlgGridHeader>
        <EcologyInputLine :data="data" name="averageArableParcelSize" />
        <EcologyInputLine :data="data" name="waterErosionRisk" />
        <EcologyInputLine
          :data="data" name="permanentGrassland"
          show-percent-of="cultivated"
          @input="update"
        />
        <EcologyInputLine
          :data="data" name="slopedCount"
          unit=""
          @input="update"
        />
        <EcologyInputLine
          :data="data" name="slopeReductionCount"
          unit=""
          @input="update"
        />
        <EcologyInputLine
          :data="data" name="gullyGreeningCount"
          show-percent-of="slopedCount" :percent-data="data"
          unit=""
          @input="update"
        />
        <DlgGridHeader>
          <IxRes>dlgCert.ecology.erosion.headers.arable</IxRes>
        </DlgGridHeader>
        <EcologyInputLine
          :data="data" name="waterErosionRiskWithLaneGreeningCount"
          unit=""
          @input="update"
        />
        <EcologyInputLine
          :data="data" name="stripTill"
          unit="ha" show-percent-of="maize"
        />
        <DlgGridHeader>
          <IxRes>dlgCert.ecology.erosion.headers.other</IxRes>
        </DlgGridHeader>
        <EcologyInputLine
          :data="data" name="noPlow"
          show-percent-of="cultivated"
        />
        <EcologyInputLine
          :data="data" name="waterErosionRiskWithPerpendicularLanesCount"
          unit="" show-percent-of="waterErosionRiskCount"
          @input="update"
        />
      </EcologyInputGrid>
    </div>
  </transition>
</template>

<script>
import EcologyInputGrid from './elements/EcologyInputGrid'
import DlgGridHeader from './elements/DlgGridHeader'
import EcologyInputLine from './elements/EcologyInputLine'

import ApiMixin from '@dlg-cert/mixins/ApiMixin'

export default {
  components: {
    EcologyInputGrid,
    DlgGridHeader,
    EcologyInputLine
  },
  mixins: [
    ApiMixin
  ],
  computed: {
    apiUrl () {
      return `/api/v2/dlg-cert/${this.$route.params.orgUnitId}/ecology/${this.$route.params.year}/erosion`
    }
  }
}
</script>

<style lang="scss" scoped>
.v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-active, .v-leave-active {
  transition: opacity 0.3s;
}
</style>
