<script>
import DlgHeader from './DlgHeader'

export default {
  functional: true,
  render (h, context) {
    return [
      h(DlgHeader, {
        class: 'dlg-grid-header'
      }, [context.scopedSlots.default()])
    ]
  }
}
</script>

<style lang="scss" scoped>
.dlg-grid-header {
  margin: 0;
  grid-column: span 3;
}
</style>
