<template lang="html">
  <div class="dlg-input-description">
    <IxRes :context="context">dlgCert.{{ pillar }}.{{ $route.name }}.labels.{{ name }}</IxRes>

    <VPopover
      v-if="hasDescription || $scopedSlots.description"
      trigger="hover"
    >
      <span class="description-icon">
        <a href="#" @click.prevent>
          <FontAwesomeIcon :icon="icon" />
        </a>
      </span>
      <template #popover>
        <slot name="description">
          <IxRes :context="context">dlgCert.{{ pillar }}.{{ $route.name }}.descriptions.{{ name }}</IxRes>
        </slot>
      </template>
    </VPopover>
  </div>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import {VPopover} from 'v-tooltip'

import {getPillar} from '@dlg-cert/helpers'

export default {
  components: {
    VPopover,
    FontAwesomeIcon
  },
  props: {
    context: Object,
    name: String,
    hasDescription: Boolean
  },
  computed: {
    icon () {
      return faInfoCircle
    },
    pillar () {
      return getPillar(this.$route)
    }
  }
}
</script>

<style lang="scss" scoped>
.dlg-input-description {
  display: flex;
  align-items: center;
}

.description-icon {
  margin-left: 0.5em;
}
</style>
